<template>
    <HlamlHero
        :title="entry.heroTitle || entry.title"
        :description="entry.heroDescription"
        :images="entry.heroImages"
        background-style="red"
        class="hero--home hlaml-hero--home"
    >
        <template #top>
            <NuxtLink
                class="hlaml-hero__logo"
                to="/hetluktalleenmetlogistiek"
                :data-slider-items="entry.stories?.length || 2"
            >
                <HlamlLogo width="680" />
            </NuxtLink>

            <HlamlStorySlider :stories="entry.stories" />
        </template>

        <template #default>
            <CraftLink
                v-if="entry.callToAction && entry.callToAction.url"
                :link="entry.callToAction"
                appearance="button"
                class="base-button--quaternary"
                icon="arrow-right"
                :measure-gtm-cta="true"
            >
                {{ entry.callToAction.text }}
            </CraftLink>
        </template>
    </HlamlHero>
</template>

<script setup>
defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    }
});
</script>
