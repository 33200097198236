<template>
    <div>
        <TemplateHlaml
            class="hlaml--home"
            :entry="entry"
        >
            <template #hero>
                <HlamlHeroHome :entry="entry" />
            </template>

            <template #cta>
                <HlamlCtaSection
                    v-for="(cta, index) in entry.ctaBlocks"
                    :key="`cta-${index}`"
                    :title="cta.sectionTitle"
                    :rich-text="cta.richText"
                    :call-to-action="cta.callToAction"
                    :secondary-call-to-action="cta.secondaryCallToAction"
                    :images="cta.images"
                />
            </template>
        </TemplateHlaml>
    </div>
</template>

<script setup>
definePageMeta({
    layout: 'hlaml'
});

import HlamlHomeQuery from '~/graphql/queries/hlaml/Home.graphql';
const { variables }  = useCraftGraphql();

// We don't need the URI for this query
delete variables.uri;

const response = await useAsyncQuery({
    query: HlamlHomeQuery,
    variables
});

const { craftEntry: entry } = useCraftPage(response);
</script>

<style lang="less">
.hlaml--home {
    .hlaml__cta .hlaml-cta-section:nth-child(even) {
        background-color: var(--color-lightest);

        .section__title,
        .section__description {
            color: var(--color-darkestblue);
        }

        &:before {
            background-color: var(--color-lightest);
        }
    }
}
</style>
