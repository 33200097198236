<template>
    <NuxtLink
        v-if="entry && entry.title && entry.link"
        :to="entry.link.url"
        class="hlaml-story-card"
        @click="useGtm()?.trackEvent({
            event: 'cta_click',
            ctaText: entry.title,
            ctaUrl: entry.link.element?.uri || entry.link.url
        })"
    >
        <BaseImage
            v-if="image"
            class="hlaml-story-card__image"
            :image="image"
            sizes="(max-width: 576px) 420px, 780px"
            fetchpriority="high"
            loading="eager"
        />

        <div class="hlaml-story-card__content">
            <h3
                class="hlaml-story-card__title"
            >
                {{ entry.title }}
            </h3>


            <BaseButton
                class="hlaml-story-card__button base-button--quaternary"
                icon="arrow-right"
                size="medium"
                appearance="button"
            >
                {{ entry.link.text }}
            </BaseButton>
        </div>
    </NuxtLink>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { toRefs } from 'vue';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
});

const { featuredImage } = toRefs(props.entry);
const image = featuredImage?.value?.[0];
</script>

<style lang="less" src="./StoryCard.less" />
