<template>
    <BaseSection
        v-if="stories && stories.length"
        section-width="bleed"
        class="section--no-padding"
    >
        <template #default>
            <BaseSlider
                class="slider--stories"
                :controls-enabled="false"
                :items="stories"
            >
                <template v-slot:slide="{ item, isVisible }">
                    <HlamlStoryCard
                        :entry="item"
                        :data-visible="isVisible"
                    />
                </template>
            </BaseSlider>
        </template>
    </BaseSection>
</template>

<script setup>
defineProps({
    stories: {
        type: Array,
        required: true,
        default: () => []
    },
});
</script>
